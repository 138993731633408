import { CurrencyFromData, CurrencyToData, NestedPairToData, PairFromData } from '@exchanger/common'
import Image from 'next/image';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

interface ModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  pairsPopular?: PairFromData;
  pairs: PairFromData | NestedPairToData;
  isFixed: boolean;
  isToPairs: boolean;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  selectCurrency: (data: CurrencyToData | CurrencyFromData) => void;
}

const Modal: FC<ModalProps> = ({
  showModal,
  setShowModal,
  pairsPopular = {},
  pairs = {},
  isFixed,
  isToPairs,
  searchTerm,
  setSearchTerm,
  selectCurrency
}) => {
  const { t } = useTranslation()
  useEffect(() => {
    if (!showModal) {
      setSearchTerm('');
    }
  }, [showModal, setSearchTerm]);

  if (!showModal) return null;

  const allPairs = { ...pairs, ...pairsPopular };
  const filteredPairs = Object.values(allPairs).filter((pair) => {
    const matchesSearchTerm = pair.currency.toLowerCase().includes(searchTerm.toLowerCase()) || pair.name.toLowerCase().includes(searchTerm.toLowerCase());
    if (isToPairs) {
      const currencyTo = pair as CurrencyToData;
      return matchesSearchTerm && (isFixed ? currencyTo?.fixed === true : currencyTo?.float === true);
    }
    return matchesSearchTerm;
  });

  const handleClose = () => {
    setShowModal(false);
    setSearchTerm('');
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[99999] bg-black bg-opacity-50" onClick={handleClose}>
      <div className="bg-[#1b1b1b] w-screen h-screen mx-auto p-2 pt-0 pt-2 sm:pt-0  sm:p-2 sm:rounded-lg shadow-lg relative overflow-y-auto sm:max-w-md sm:h-auto sm:max-h-[150vh] sm:border sm:border-gray-700 sm:border-b-0" onClick={(e) => e.stopPropagation()}>
        <div className="absolute inset-x-0 top-0 sm:relative sm:inset-auto sm:top-auto flex justify-between items-center p-4 sm:p-1 bg-[#1b1b1b] sm:bg-transparent z-[99999]">
          <button
            className="w-[40px] h-[40px] flex items-center justify-center m-2 text-gray-600 bg-[#1b1b1b] rounded p-2 hover:bg-white hover:text-[#1b1b1b]"
            onClick={handleClose}
            style={{ fontSize: '24px' }}
          >
            ←
          </button>
          <h2 className="text-xl font-semibold text-white">{t('exchange:widget.select.title')}</h2>
          <button
            className="w-[40px] h-[40px] flex items-center justify-center m-2 text-gray-600 bg-[#1b1b1b] rounded p-2 hover:bg-white hover:text-[#1b1b1b]" onClick={handleClose}
            style={{ fontSize: '28px' }}
          >
            &times;
          </button>
        </div>
        <input
          placeholder={t('exchange:widget.select.search')}
          className="w-full h-10 border border-gray-300 rounded-lg p-2 mb-2 mt-20 sm:mt-4 text-black"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="overflow-y-auto max-h-[calc(100vh-8rem)] sm:max-h-80">
          {searchTerm === '' && Object.keys(pairsPopular).length > 0 && (
            <>
              <div className="text-white font-semibold mb-2">{t('exchange:widget.select.popular')}</div>
              {Object.values(pairsPopular).map((pair, index) => (
                <div
                  key={index}
                  className="flex items-center cursor-pointer gap-2 hover:bg-gray-800 p-2 rounded"
                  onClick={() => selectCurrency(pair)}
                >
                  <Image
                    src={`/coins/${pair.currency.toLowerCase()}.png`}
                    alt={`Exchange ${pair.currency}`}
                    width={32}
                    height={32}
                  />
                  <span className="text-lg text-white">{pair.currency} ({pair.chain})</span>
                  <span className="text-sm text-gray-400">{pair.name}</span>
                </div>
              ))}
              <hr className="border-t my-2 border-gray-600" />
            </>
          )}
          {filteredPairs.map((pair, index) => (
            <div
              key={index}
              className="flex items-center cursor-pointer gap-2 hover:bg-gray-800 p-2 rounded"
              onClick={() => selectCurrency(pair)}
            >
              <Image
                src={`/coins/${pair.currency.toLowerCase()}.png`}
                alt={`Exchange ${pair.currency}`}
                width={32}
                height={32}
              />
              <span className="text-lg text-white">{pair.currency} ({pair.chain})</span>
              <span className="text-sm text-gray-400">{pair.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modal;
